/*.selector {
	color: red;

	@include respond-to('small') {
		color: blue;
	}
}*/

.section-footer{
	display: block;
	
	.footer-row{
		@include make-row();
		margin-top:0;
		margin-bottom: 0;
		padding: 0;
		list-style: none;
		> li {
			@include make-sm-column(4);
			@include make-md-column(3);
			&:last-child{
				@include make-sm-column(12);
				@include make-md-column(3);
			}
		}
	}
	@include respond-to(small){
		padding-top: 30px;
	}
	
}

.footer-contain{
	display: block;
	margin: 25px 0;
	a{
		color: $clr-black-two;
		&:hover{
			text-decoration: underline;
		}
	}
	.head-txt{
		padding-bottom: 15px;
		display:block;
		border-bottom: 1px $clr-orange-light-one  solid;
		h4{
			font-family: $font-family-serif;
			font-weight: 400;
			@include font-size(1.8);
			margin: 0;
			color: $clr-brown-one;
			@include respond-to(medium){
				@include font-size(2);
			}
		}
		@include respond-to(medium){
			padding-bottom: 18px;
		}
	}
	.btm-content{
		padding-top: 15px;
		display: block;
		color: $clr-black-two;
		.para{
			margin: 0;
			strong{
				font-weight: 700;
			}
			@include font-size(1.5);
			line-height: 1.7;
			
		}
		@include respond-to(medium){
			padding-top: 23px;
		}
	}
	@include respond-to(small){
		margin: 0;
	}
}

.icn{
	display: block;
	position: relative;
	padding-left: 15px;
	&:before{
		content: "";
		display: inline-block;
		width: 11px;
		height: 11px;
		position: absolute;
		top: 7px;
		left: 0;
	}
	&.icn-phone{
		&:before{
			background: url(../img/icn-call.png) no-repeat;
		}
	}
	&.icn-mail{
		padding-left: 24px;
		word-wrap: break-word;
		white-space: normal;
		&:before{
			background: url(../img/icn-mail.png) no-repeat;
			width: 18px;
			height: 12px;
			top: 6px;
		}
	}
}

@mixin placeholder {
	&::-moz-placeholder {
		@content
			}
	&::-webkit-input-placeholder {
		@content
			}
	&:-ms-input-placeholder {
		@content
			}
}

@mixin placeholder-style {
	color: red;
}


.form-donate{
	.form-group {
		margin-bottom: 13px;
	}
	.form-control{
		border:none;
		border-bottom: 1px $clr-orange-light-one  solid;
		height: 32px;
		padding: 6px 0;
		@include borderRadius(0px);
		@include box-shadow(0px 0px 0px rgba(0, 0, 0, 0.075) inset );
		font-weight: 400i;
		@include font-size(1.5);
		color: $clr-black-two;
		@include x-appearance(none);
		@include placeholder{
			color: $clr-black-two;
		}
	}
	.btn-yellow{
		margin-top: 7px;
		width: 100%;
		max-width: 212px;
	}
}



.footer-rights{
	text-align: center;
	border-top: 1px $clr-orange-light-one solid;
	margin-top: 20px;
	padding: 20px 0;
	p{
		margin: 0;
		@include font-size(1.4);
		span{
			display: block;
			margin-bottom: 10px;
		}
	}
	.logo-speedline{
		display: inline-block;
	}
	@include respond-to(small){
		margin-top: 35px;
		padding: 30px 0;
	}
}


.form-options {
	.fs-dropdown {
		margin: 0;
		font-family:$font-family-sans-serif;
	}
	.fs-dropdown-selected {
		outline: none;
		height: 32px;
		line-height: 32px;
		border: none;
		border-bottom: 1px $clr-orange-light-one solid;
		@include borderRadius(0px);
		@include font-size(1.5);
		padding-left: 20px;
		color: $clr-black-two;
		@include box-shadow(1px 1px 5px 0px rgba(50, 50, 50, 0));
		padding-left: 0;
	}
	.fs-dropdown-item {
		padding: 0 20px;
		color: $clr-brown-one;
		border: none;
		border-bottom: 1px solid white;
		outline: none;
		@include borderRadius(0px);
		@include box-shadow(1px 1px 5px 0px rgba(50, 50, 50, 0));
		background-color: $clr-orange-light-one;
		height: 32px;
		line-height: 32px;
		padding: 0 15px;
		&:hover, &:focus{
			background-color: $clr-yellow-one;
		}
	}
	.fs-dropdown-options.fs-scrollbar {
		.fs-scrollbar-content {
			@include borderRadius(0px);
			@include box-shadow(1px 1px 5px 0px rgba(50, 50, 50, 0));
			border: none;
			border-top: 1px solid $clr-orange-light-one;
		}
	}

	.fs-dropdown-item:last-child {
		border-bottom: 0;
		border-radius: 0 0 0px 0px;
	}

	.fs-dropdown-open {
		.fs-dropdown-options {
			@include borderRadius(0px);
			border: none;
		}
	}
}


.embed-responsive-16by9 {
	&.ftr-map{
		@include respond-to(small){
			margin-top: 30px;
		}
		@include respond-to(medium){
			padding-bottom: 92%;
			margin-top: 0;
		}
	}
}







