/* 
* 	Core Owl Carousel CSS File
*	v1.3.3
*/

/* clearfix */

.owl-carousel {
.owl-wrapper {
&:after {
content: ".";
display: block;
clear: both;
visibility: hidden;
line-height: 0;
height: 0;
}
display: none;
position: relative;
-webkit-transform: translate3d(0px, 0px, 0px);
}
display: none;
position: relative;
width: 100%;
-ms-touch-action: pan-y;
.owl-wrapper-outer {
overflow: hidden;
position: relative;
width: 100%;
&.autoHeight {
-webkit-transition: height 500ms ease-in-out;
-moz-transition: height 500ms ease-in-out;
-ms-transition: height 500ms ease-in-out;
-o-transition: height 500ms ease-in-out;
transition: height 500ms ease-in-out;
}
}
.owl-item {
float: left;
}
}

/* display none until init *//* 
* 	Core Owl Carousel CSS File
*	v1.3.3
*/


/* clearfix */

.owl-carousel {
	.owl-wrapper {
		&:after {
			content: ".";
			display: block;
			clear: both;
			visibility: hidden;
			line-height: 0;
			height: 0;
		}
		display: none;
		position: relative;
		-webkit-transform: translate3d(0px, 0px, 0px);
	}
	display: none;
	position: relative;
	width: 100%;
	-ms-touch-action: pan-y;
	.owl-wrapper-outer {
		overflow: hidden;
		position: relative;
		width: 100%;
		&.autoHeight {
			-webkit-transition: height 500ms ease-in-out;
			-moz-transition: height 500ms ease-in-out;
			-ms-transition: height 500ms ease-in-out;
			-o-transition: height 500ms ease-in-out;
			transition: height 500ms ease-in-out;
		}
	}
	.owl-item {
		float: left;
	}
}


/* display none until init */

.owl-controls {
	.owl-page,
	.owl-buttons div {
		cursor: pointer;
	}
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}


/* mouse grab icon */

.grabbing {
	cursor: url(grabbing.png) 8 8, move;
}


/* fix */

.owl-carousel {
	.owl-wrapper,
	.owl-item {
		-webkit-backface-visibility: hidden;
		-moz-backface-visibility: hidden;
		-ms-backface-visibility: hidden;
		-webkit-transform: translate3d(0, 0, 0);
		-moz-transform: translate3d(0, 0, 0);
		-ms-transform: translate3d(0, 0, 0);
	}
}

.owl-controls {
.owl-page, .owl-buttons div {
cursor: pointer;
}
-webkit-user-select: none;
-khtml-user-select: none;
-moz-user-select: none;
-ms-user-select: none;
user-select: none;
-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

/* mouse grab icon */

.grabbing {
cursor: url(grabbing.png) 8 8, move;
}

/* fix */

.owl-carousel {
.owl-wrapper, .owl-item {
-webkit-backface-visibility: hidden;
-moz-backface-visibility: hidden;
-ms-backface-visibility: hidden;
-webkit-transform: translate3d(0, 0, 0);
-moz-transform: translate3d(0, 0, 0);
-ms-transform: translate3d(0, 0, 0);
}
}

/*
* 	Owl Carousel Owl Demo Theme 
*	v1.3.3
*/

.owl-controls {
	margin-top: 10px;
	text-align: center;
	.owl-buttons div {
		color: #FFF;
		display: inline-block;
		zoom: 1;
		*display: inline;
		/*IE7 life-saver */
		margin: 5px;
		padding: 3px 10px;
		font-size: 12px;
		-webkit-border-radius: 30px;
		-moz-border-radius: 30px;
		border-radius: 30px;
		background: #869791;
		filter: Alpha(Opacity = 50);
		/*IE7 fix*/
		opacity: 0.5;
	}
	&.clickable .owl-buttons div:hover {
		filter: Alpha(Opacity = 100);
		/*IE7 fix*/
		opacity: 1;
		text-decoration: none;
	}
	.owl-page {
		display: inline-block;
		zoom: 1;
		*display: inline;
		/*IE7 life-saver */
		span {
			display: block;
			width: 12px;
			height: 12px;
			margin: 5px 7px;
			filter: Alpha(Opacity = 50);
			/*IE7 fix*/
			opacity: 0.5;
			-webkit-border-radius: 20px;
			-moz-border-radius: 20px;
			border-radius: 20px;
			background: #869791;
		}
		&.active span {
			filter: Alpha(Opacity = 100);
			/*IE7 fix*/
			opacity: 1;
		}
	}
	&.clickable .owl-page:hover span {
		filter: Alpha(Opacity = 100);
		/*IE7 fix*/
		opacity: 1;
	}
	.owl-page span.owl-numbers {
		height: auto;
		width: auto;
		color: #FFF;
		padding: 2px 10px;
		font-size: 12px;
		-webkit-border-radius: 30px;
		-moz-border-radius: 30px;
		border-radius: 30px;
	}
}

/* Styling Next and Prev buttons */

/* Clickable class fix problem with hover on touch devices */
/* Use it for non-touch hover action */

/* Styling Pagination*/

/* If PaginationNumbers is true */

/* preloading images */

.owl-item.loading {
	min-height: 150px;
	background: url(AjaxLoader.gif) no-repeat center center;
}

