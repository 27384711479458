/*.selector {
	color: red;

	@include respond-to('small') {
		color: blue;
	}
}*/

*{
	text-rendering: optimizeLegibility;
	-webkit-font-smoothing: antialiased;
	@include transition(all 0.5s ease);
}


.body-container{
	background: white;
	display: block;
	width: 100%;
	height: 100%;
	position: relative;
	&:before{
		content:"";
		width: 100%;
		background: url(../img/body-bg.png) no-repeat center $clr-orange-light-one;
		height: 521px;
		display: block;
		position: absolute;
		z-index: 1;
		@include transition(height .1s ease-in-out);
		@include respond-to(small){
			height: 521px;
		}
	}
	.contained{
		display: block;
		position: relative;
		z-index: 2;
	
	}
	&.cms-body{
		&:before{
			height: 420px;
		}
	}
}

.section-header{
	position: relative;
	.navbar-default{
		background: none;
		border: none;
		text-align: center;
		.navbar-brand{
			display: inline-block;
			width: 100%;
			max-width: 210px;
			float: none;
			padding: 0;
			height: auto;
			img{
			  @include img-responsive;
				width: 100%;
			}
			@include respond-to(small){
				max-width: 260px;
			}
			@include respond-to(medium){
				max-width: 280px;
			}
		}
		.navbar-collapse{
			padding: 0;
			border-top: 0px solid $clr-grey-two;
			@include respond-to(small){
				border-top: 0px solid $clr-grey-two;
			}
		}
		&.navbar{
			margin-bottom: 0;
			@include respond-to(small){
				@include borderRadius(0px);
			}
		}
		.btn-christ{
			position: absolute;
			left: 0;
			margin: 0;
			padding: 4px 11px;
			border: 2px solid $clr-orange-light-one;
			border-radius: 6px;
			img{
				width: 10px;
			}
			&:hover, &:focus{
			
				border-color: $clr-yellow-one;
			}
			@include respond-to(small){
				display: none;
			}
		}
		.navbar-toggle{
			position: absolute;
			right: 0;
			margin: 0;
			padding: 7px 8px;
			border: 2px solid $clr-orange-light-one;
			border-radius: 6px;
			.icon-bar{
				width: 16px;
				background-color:  $clr-yellow-one;
			}
			&:hover, &:focus{
				border-color: $clr-yellow-one;
				background-color: #fff;
			}
		}
		
	}
	.navbar-header{
		display: block;
		float: none;
		padding-top: 15px;
		position: relative;
		@include respond-to(small){
			padding-top: 26px;
		}
	}
	
	
	.navbar-nav{
		margin: 15px -15px;
		> li{
			@include font-size(1.6);
			font-weight: 600;
			text-transform: capitalize;
			> a{
				padding: 0;
				line-height: 1;
				text-align: center;
				/*border-top: 1px $clr-grey-four solid;*/
				span{
					padding: 13px 16px;
					display: inline-block;
					position: relative;
					@include respond-to(small){
						padding: 10px 16px;
					}
				}
				&:hover, &:focus{
					background-color: transparent;
				}
			}
			&.active{
				> a{
					background-color: transparent;
					span{
						&:before{
							content: "";
							border-top: 1px $clr-brown-one solid;
							position: absolute;
							top: -1px;
							width: 100%;
							display: block;
							left: 0;
						}
					}
					&:hover, &:focus{
						background-color: transparent;
					}
				}
			}
			@include respond-to(small){
				width: 20%;
				float: left;
				> a{
				
					span{
						padding: 22px 16px;

					}
				}
				
			}	
			@include respond-to(medium){
				@include font-size(1.9);
			}
		}
		@include respond-to(small){
			float: none;
			margin: 0;
			width: 100%;
			margin-top: 20px;
		}
		@include respond-to(small){
			border-top: 1px $clr-grey-two solid;
		}
	}
}

.pastro-info{
	float: none;
	margin: 20px 0;
	text-align: center;
	width: 100%;
	display: block;
//	color: $clr-grey-two;
	line-height: 1.5;
	p{
		margin-bottom: 0;
//		color: $clr-grey-two;
	}
	strong{
		font-weight: 700;
		color: $clr-brown-one;
		text-transform: uppercase;
	}
	@include respond-to(small){
		float: left;
		text-align: left;
		margin-top: 72px;
		display: inline-block;
		width: auto;
		margin-bottom: 0;
	}
	@include respond-to(medium){
		margin-top: 82px;
	}
}
.mobile-menu{
	width: 100%;
	display: block;
	@include clearfix;
	display: none;
	@include respond-to(small){
		display: block !important;
		position: absolute;
		top: 0;
		width: 100%;
	}
}
.social-networks{
	float: none;
	margin: 20px 0;
	text-align: center;
	width: 100%;
	display: block;
	.scl-icn{
		display: block;
		padding: 0;
		margin: 0;
		list-style: none;
		margin-bottom: 11px;
		max-width: 120px;
		margin: 0 auto;
		margin-bottom: 11px;
		>li{
			display: inline-block;
			@include font-size(0);
			a{
				@include font-size(1.2);
				width: 26px;
				height: 26px;
				display: block;
				@include borderRadius(50%);
				text-align: center;
				background-color: $clr-grey-three;
				color: white;
				img{
					width: 25px;
					height: 25px;
				}
				&:hover, &:focus{
					opacity: 0.65;
				}
			}
		}
		@include respond-to(small){
			display: inline-block;
			padding: 0;
			margin: 0;
			list-style: none;
			max-width: initial;
			margin-bottom: 11px;
		}
	}

	.btn-stream{
		width: auto;
		display: inline-block;
		@include respond-to(small){
			width: auto;
			display: block;
		}
	}
	@include respond-to(small){
		float: right;
		text-align: right;
		margin-top: 68px;
		display: inline-block;
		width: auto;
		margin-bottom: 0;
	}
	@include respond-to(medium){
		margin-top: 77px;
	}
}

.language{
	display: block;
	position: relative;
	z-index: 100;
	text-align: center;
	margin-top: 20px;
	@include respond-to(small){
		display: inline-block;
		position: absolute;
		right:0;
		top: 20px;
		margin-top: 0;
	}
}



