@keyframes HEARTBEAT {
	0% {
		transform: scale(1);
	}
	5% {
		transform: scale(1.3);
	}
	10% {
		transform: scale(1.1);
	}
	15% {
		transform: scale(1.5);
	}
	50% {
		transform: scale(1);
	}
	100% {
		transform: scale(1);
	}
}
@keyframes record {
	0% {
		opacity: 0.1;
	}
	10% {
		opacity: 0.2;
	}
	15% {
		opacity: 0.4;
	}
	20% {
		opacity: 0.6;
	}
	75% {
		opacity: 0.8;
	}
	100% {
		opacity: 0.1;
	}
}





.main-body{
	margin-top: 20px;
	@include respond-to(small){
		margin-top: 0;
	}
}




.section-donate{
	margin: 0 -15px;
	display: block;
	text-align: center;
	padding: 46px 20px 25px 20px;
	position: relative;
	margin-top: 5px;
	background: url(../img/bg-donate.png) no-repeat center bottom $clr-yellow-one;
	min-height: 190px;

	.btn-section{
		text-align: center;
		margin-bottom: 20px;
		display: block;
		position: relative;
	}
	.txt-para{
		display: block;
		.head-txt{
			display: inline-block;
			@include font-size(1.8);
			font-weight: 600;
			margin: 0 0 10px 0;
			color: $clr-brown-one;
			line-height: 1.4;
			@include respond-to(small){
				@include font-size(2);
				margin: 0 0 10px 0;
				line-height: 1.3;
			}
			@include respond-to(medium){
				@include font-size(2);
				margin: 0 0 6px 0;	
			}
		}
		.sub-txt{
			@include font-size(1.4);
			color: $clr-brown-one;
			text-transform: uppercase;
			margin: 0;
			@include respond-to(small){
				@include font-size(1.5);
			}
		}
		@include respond-to(small){
			padding: 0 10%;
		}
		@include respond-to(medium){
			padding: 0;
		}
	}
	@include respond-to(small){
		margin-left: 0;
		margin-right: 0;
		min-height: 190px;
		padding: 46px 15px 32px 15px;
		width: 100%;	
	}
}

.btn-yellow{
	@include button-variant($clr-white-one, $clr-yellow-one, $clr-yellow-one);
	@include button-size($padding-small-vertical, $padding-small-horizontal, $font-size-large, $line-height-small, $btn-border-radius-small);
	border: none;
	display: inline-block;
	text-transform: uppercase;
	font-weight: 600;
	@include borderRadius(6px);
	&:hover, &:focus{
		text-decoration: none;
	}
}

.btn-brown{
	@include button-variant($clr-white-one, $clr-brown-one, $clr-brown-one);
	@include button-size($padding-small-vertical, $padding-small-horizontal, $font-size-large, $line-height-small, $btn-border-radius-small);
	border: none;
	display: inline-block;
	text-transform: uppercase;
	font-weight: 600;
	@include borderRadius(6px);
	&:hover, &:focus{
		text-decoration: none;
	}
}

.btn-stream{
	@include button-variant($clr-brown-one, transparent, $clr-brown-one);
	@include button-size($padding-small-vertical, $padding-small-horizontal, $font-size-small, $line-height-small, $btn-border-radius-small);
	@include font-size(1.5);
	padding: 5px 10px 5px 34px;
	display: inline-block;
	text-transform: uppercase;
	font-weight: 500;
/*	width: 115px;
	height: 26px;*/
	display: block;
	border: 1px rgba($clr-brown-one,.2) solid;
	@include borderRadius(5px);
	position: relative;
	&:before{
		width: 14px;
		height: 11px;
		display: block;
		content: "";
		background: url(../img/icn-signal.svg) no-repeat;
		position: absolute;
		left: 11px;
		top: 50%;
		margin-top: -5.5px;
		background-size: 14px auto;
		animation: record 1s infinite;
	}
	&:hover,&:focus{
		text-decoration: none;
	}
}

.btn-donate{
	position: absolute;
	left:50%;
	width: 63px;
	height: 63px;
	padding: 5px;
	display: block;
	top: 0;
	margin-top: -30px;
	margin-left: -31.5px;
	background-color: white;
	@include borderRadius(50%);
	overflow: hidden;
	/*> span{
		@include borderRadius(50%);
		border: none;
		display: block;
 	  width: 100%;
		height: 100%;
		background: url(../img/icn-donate.png) no-repeat center $clr-yellow-one;
	}*/
	/*img{
		@include img-responsive;
	}*/
	
	.heart-wrap{
		z-index: 1;
		width: 100%;
		height:100%;
		display: block;
		background: blue;
		@include borderRadius(50%);
		background-color: $clr-yellow-one;
		.heart{
			width: 100%;
			height: 100%;
			display: block;
			background-size: 25px auto;
			background-repeat: no-repeat;
			background-image: url(../img/icn-heartbeat.svg);
			background-position: center;
		}
	}
	
	.heart {
		animation: HEARTBEAT 2.5s infinite;
	}
}


.section-categories{
	display: block;

	.category-row{
		@include make-row();
		margin-top:0;
		margin-bottom: 0;
		padding: 0;
		list-style: none;
		> li {
			@include make-sm-column(4);
		}
	}
	@include respond-to(small){
		margin-top: 30px;
		margin-bottom: 30px;
	}
}

.category-holder{
	background: #2d2d2d;
	color: white;
	position: relative;
/*	min-height: 123px;*/
	width: 100%;
	max-width: 310px;
	margin: 20px auto;
	> a{
		display: block;
		width: 100%;
		&:hover, &:focus{
			text-decoration: none;
		}
	}
	.img-holder{
		display: block;
		/*padding-top: 123px;*/
		padding-top: 42%;
		position: relative;
		z-index: 1;
		width: 100%;
		height: 100%;
		overflow: hidden;
		&:before{
			content: "";
			background-color:rgba($clr-brown-one, 0.6);
			position: absolute;
			top: 0;
			width: 100%;
			height: 100%;
			display: block;
			z-index: 2;
			transition:background-color 1s ease;
      }
		img{
			@include img-responsive;
			position: absolute;
			width: 100%;
			height: 100%;
			display: block;
			top: 0;
			z-index: 1;
			-webkit-transform: scale(1);
			transform: scale(1);
			transition: -webkit-transform 15s cubic-bezier(0.19, 1, 0.22, 1);
			transition: transform 15s cubic-bezier(0.19, 1, 0.22, 1); 
		}
	}
	.category-content{
		display: block;
		color: white;
		position: absolute;
		z-index: 2;
		padding: 0 15px;
		bottom: 15px;
		width: 100%;
		transition:bottom 400ms ease;
		.head-txt{
			display: block;
			margin: 0;
			margin-bottom: 5px;
			padding-bottom: 4px;
			font-family: $font-family-serif;
			font-weight: 700;
			color: white;
			@include font-size(2.2);
			position: relative;
			text-transform: capitalize;
			&:after{
				width: 0px;
				height: 1px;
				background: rgba($clr-orange-light-one,0.1);
				content: "";
				display: block;
				transition:width 1s ease;
				bottom: -1px;
				position: absolute;
			}
			@include respond-to(medium){
				@include font-size(3);
			}
		}
		.para-txt{
			display: block;
			color: $clr-yellow-one;
			@include font-size(1.4);
			text-transform: capitalize;
			@include respond-to(small){
				@include font-size(1.3);
			}
			@include respond-to(medium){
				@include font-size(1.6);
			}
		}
		@include respond-to(small){
		
			bottom: 10px;
		}
		@include respond-to(medium){
			bottom: 17px;
			padding: 0 19px;
		}
	}
	&:hover,&:focus{
		.img-holder{
			&:before{
				background-color:rgba($clr-brown-one, .8);
			}
		}
		.category-content{
			bottom: 30px;

			.head-txt{
				&:after{
					width: 100%;
				}
			}
		}
	}
	&:hover, &:focus{
		.img-holder{
			img{
				-webkit-transform: scale(1.1);
				transform: scale(1.1);
			}
		}
	}
	@include respond-to(small){
		margin: 0;
	}
}


.section-message{
	display: block;
	background: $clr-orange-light-one;
	padding: 25px 0;
	.wrap{
		text-align: center;
		display: block;
		line-height: 1;
		min-height: 37px;
		@include respond-to(small){
			min-height: 90px;
		}
		@include respond-to(medium){
			min-height: 45px;
		}
	}
	.message-row{
		@include make-row();
		margin-top:0;
		margin-bottom: 0;
		padding: 0;
		list-style: none;
		> li {
			@include make-sm-column(4);
			&:nth-child(1){
				.wrap{
					text-align: center;
					position: relative;
					
					@include respond-to(small){
						text-align: right;
						padding-right: 10px;
						&:before{
							position: absolute;
							width: 1px;
							height: 100%;
							content: "";
							right: -15px;
							background-color: $clr-grey-nine;
							display: block;
						}
					}
					
					@include respond-to(medium){
						padding-right: 50px;
					}
				}
				
			}
			&:nth-child(3){
				.wrap{
					text-align: center;
				
					position: relative;
					
					@include respond-to(small){
						&:before{
							position: absolute;
							width: 1px;
							height: 100%;
							content: "";
							left: -15px;
							background-color: $clr-grey-nine;
							display: block;
						}
						text-align: left;
						padding-left:10px;
					}
					@include respond-to(medium){
						padding-right: 20px;
					}
				}
				
			}
		}
	}
	
	.message-title{
		display: inline-block;
		text-align: left;
		position: relative;
		padding-left: 36px;
	
		&:before{
			width: 20px;
			height: 25px;
			background: url(../img/icn-speaker.png) no-repeat;
			content: "";
			position: absolute;
			left: 0;
			top: 50%;
			margin-top: -12.5px;

			opacity: .6;
		}
		h3{
			font-family: $font-family-serif;
			font-weight:400;
			color: $clr-brown-one;
			line-height: 1;
			@include font-size(1.8);
			margin: 0;
			display: table-cell;
			vertical-align: middle;
			height: 35px;
			text-transform: uppercase;
			@include respond-to(medium){
				@include font-size(2);
			}
		}
	}
	.message-para{
		color: $clr-brown-one;
		display: table;
		min-height: 25px;
		vertical-align: middle;
		text-align: center;
		width: 100%;
		padding:15px 15px;
		p{
			margin: 0;
			display: table-cell;
			vertical-align: middle;
			@include font-size(1.45);
			font-weight: 700;
			em{
				font-family: $font-family-serif;
				font-weight: 400;
				display: block;
			}
			
			@include respond-to(medium){
				@include font-size(1.5);
			}
		}
		@include respond-to(small){
			padding: 2.5px 0;
		}
	}
	.message-btns{
		padding: 0;
		display: block;
		> span{
			display: inline-block;
			width: auto;
			padding: 4px 3px;
	    font-size: 0px;
			@include respond-to(small){
				padding: 4px 10px 4px 0;
			}
			@include respond-to(medium){
				padding: 4px 15px 4px 0;
			}
		}
		.buttons{
			padding: 5px 18px;
			width: 126px;

			@include respond-to(small){
				width: auto;
				
			}
		}
	}
	@include respond-to(small){
		padding: 30px 0;
	}
}

.christ-logo{
	position: relative;
	&:before, &:after{
		width: 8px;
		height: 11px;
		display: block;
		left:50%;
		left:50%;
		bottom: 100%;
		content: "";
		position: absolute;

		margin-left: -4px;
		z-index: 5;
	}
	&:before{
		border-left: 7px solid transparent ;
		border-right: 7px solid transparent ;
		border-bottom: 7px #13080c solid ;
		margin-left: -7px;
	}
	&:after{
		background: url(../img/icn-christ.png) no-repeat transparent;
		width: 8px;
		height: 11px;
		top: 2px;
		z-index: 6;
	}
}




.banner{
	@extend .christ-logo;
	position: relative;
	margin: 0 -15px;
	display: block;
	@include respond-to(small){
		margin: 0;
	}
}

.shadow{
	position: relative;
	&:before{
		width: 100%;
		position: absolute;
		display: block;
		content:"";
		background: url(../img/bg-banner-top.png) repeat-x  ;
		z-index: 3;
		top: -15px;
		padding-top: 15%;
		@include respond-to(small){
			height: 45px;	
			padding-top: 0;
		}
	}
}

.owl-banner-slide{
	/*&:before{
		width: 100%;
		height: 45px;		
		position: absolute;
		display: block;
		content:"";
		background: url(../img/bg-banner-top.png) repeat-x  ;
		z-index: 3;
		top:0;
	}*/
	.item{
		position: relative;
		
		&:before{
			width: 100%;
			height: 100%;			position: absolute;
			display: block;
			content:"";
			background-color: rgba($clr-brown-one, 0.45);
			z-index: 1;
			@include respond-to(small){
				background-color: rgba($clr-brown-one, 0.5);
			}
		}    
		img{
			@include img-responsive;
		}
	}
	.owl-wrapper-outer{
		@extend .shadow;
	}
	.owl-txt{
		display: block;
		background-color: $clr-brown-one;
		/*max-width:678px;*/
		padding: 20px 20px 60px 20px;

		position: relative;
		z-index: 2;
		font-family: $font-family-serif;
		font-weight: 400;
		color: white;
		text-align: center;
            text-shadow: 1px 1px 1px rgba(0,0,0,0.5);
		/*&:before{
			position:absolute;
			content: "";
			width: 16px;
			height: 16px;
			display: block;
			background: url(images/icn-owl-arw.png) no-repeat;
			right: 0;
			top: 0;
		}*/
		.owl-title{
			@include font-size(2.2);
			line-height: 1.4;
			color: white;
			margin: 0;
			font-style: italic;
			font-weight: 400;
			padding-bottom: 10px;		 
			span{
				color: $clr-yellow-one;
			}
			@include respond-to(small){
				@include font-size(2.5);		 
			}
			@include respond-to(medium){
				@include font-size(3.3);
				line-height: 1.5;
				padding-bottom: 20px;		 
			}
			
		}
		.owl-arthur{
			@include font-size(1.4);
			font-weight: 300;
			line-height: 22px;
			display: inline-block;
			width: 100%;
			max-width: 300px;
			margin: 0;
			border-top: 1px rgba($clr-brown-five,0.3) solid;
			padding-top: 10px;
			text-transform: uppercase;
			line-height: 1.5;
			span{
				color: $clr-yellow-one;	
			}
			@include respond-to(small){
				@include font-size(1.4);
				padding-top: 5px;
				border-top: 1px rgba($clr-brown-five,0.5) solid;
			}
			@include respond-to(medium){
				@include font-size(1.6);
				padding-top: 10px;
				line-height: 1.6;
			}
		}
		@include respond-to(small){
			top: 90px;
			left: 10%;
			right: 10%;
			position: absolute;
			padding: 0;
			background-color: transparent;
		}
		@include respond-to(medium){
			top: 110px;
		}
	}

	.owl-controls{
		position: absolute;
		width: 100%;
		bottom: 30px;
		margin: 0;
		.owl-page {
			span{
				width: 10px;
				height:10px;
				background: #f1ebe2;
				opacity: 0.5;
				margin: 0 2px;
				position: relative;
				@include opacity(0.3);
			}
			&.active{
				span{
					@include opacity(1);
					text-align: center;
					background: $clr-yellow-one;
					/*&:before{
						width: 4px;
						height: 4px;
						background: $clr-brown-one;
						display: block;
						content: "";
						border-radius: 50%;
						top: 2.5px;
						left: 3px;
						position: absolute;
						margin-left: -0.5px;
					}*/
				}
			}
		}
		@include respond-to(medium){
			bottom: 40px;
		}
	}
}


.section-headline{
	@extend .christ-logo;
	background: white;
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	height: 194px;
	display: block;
	margin: 0 -15px;
	.header-headline{
		@extend .shadow;
		text-align: center;
		display: table;
		width: 100%;
		height: 100%;
		&:before{
			top: 0;
			padding-top: 5%;
			@include respond-to(small){
				padding-top: 16%;
			}
		}
		.txt{
			color: white;
			margin: 0;
			display: table-cell;
			height: 100%;
			width: 100%;
			vertical-align: middle;
			@include font-size(4);
			font-family: $font-family-serif;
			font-weight: 700;
            text-shadow: $txt-shadow-one;
		}
	}
	&.header-about{
		background-image: url(../img/img-bg-about.jpg);
	}
	&.header-contact{
		background-image: url(../img/bg-contact-1.jpg);
	}
	&.header-events{
		background-image: url(../img/bg-events.jpg);
	}
	&.header-sermons{
		background-image: url(../img/img-bg-sermons-1.jpg);
	}
	@include respond-to(small){
		height: 294px;
		margin: 0;
	}
	
}


.section-body{
	display: block;
	margin: 40px 0 0 0;
}


.split-one{
	@include make-row();
	.section-left {
		@include make-sm-column(8);
		@include make-md-column(9);
	}
	.section-right {
		@include make-sm-column(4);
		@include make-md-column(3);
	}
}



.form-donation{
	display: none;
	margin-top: 30px;
	.form-donate{
		margin-top: 20px;
	}
	@include respond-to(small){
		display: block;
	}
}

.section-block{
	display: block;
	border-bottom: 1px $clr-orange-light-one solid;
	margin-bottom: 30px;
  outline: none !important;
	.title{
		@include font-size(2.6);
		font-family: $font-family-serif;
		font-weight: 700;
		position: relative;
		margin: 0;
		margin-bottom: 20px;
		color: $clr-brown-one;
		&:after{
			width: 20px;
			height: 32px;
			background:url(../img/icn-christ.svg) no-repeat;
			content: "";
			position: absolute;
			right:0;
			background-position: left center;
		}
		@include respond-to(small){
			@include font-size(3);
			margin-bottom: 30px;
		}
	}
	.btm-cnt{
		display: block;
		line-height: 1.7;
		@include font-size(1.6);
		margin-bottom: 30px;
		.sub-txt-wrap{
			margin-bottom: 30px;
			padding-bottom: 30px;
			display: block;
			text-align: center;
			border-bottom: 1px $clr-orange-light-one solid;
			.sub-txt{
				margin: 0;
			}
		}
		.sub-txt{
			color: $clr-yellow-one;
			font-weight: 700;
			line-height: 1.5;
		
		}
		img{
			@include img-responsive;
			margin: 20px 0;
			width: 100%;
			@include respond-to(small){
				margin: 30px 0;
			}
		}
		hr{
			border-color: $clr-orange-light-one;
		}
		p{
			margin-bottom: 0;
		}
		> p:not(:last-of-type){
			margin-bottom: 20px;
			@include respond-to(small){
				margin-bottom: 25px;
			}
		}
		.media{
			&:last-child{
				padding-bottom: 0;
			}
		}
		@include respond-to(small){
			margin-bottom: 50px;
		}
	}
	&.meet-team{
		.media{
			padding: 20px 0;
		}
		.media-left{
			padding: 0;
			width: 100%;
			display: block;
			padding-bottom: 30px;
			text-align: center;
			@include respond-to(medium){
				padding: 0;
				display: table-cell;
				vertical-align: top;
				padding-right: 30px;
				text-align: right;
				width: auto;
			}
		}
		.img-holder{
			width: 150px;
			height: 150px;
			@include borderRadius(50%);
			overflow: hidden;
			border: 5px $clr-yellow-one solid;
			display: inline-block;
		
			@include respond-to(medium){
				display: block;
			}
		}
		.media-object{
			width: 100%;
			max-width: 140px;
			min-width: 140px;
			margin: 0;
		}
		.media-body{
			width: 100%;
			word-break: normal;
			> p:not(:last-of-type){
				margin-bottom: 25px;
			}
			@include respond-to(medium){
				display: table-cell;
				vertical-align: top;
				width: 10000px;
			}
		}
		.media-heading{
			font-weight: 600;
			@include font-size(2.2);
			margin: 0;
			margin-bottom: 10px;
			color: $clr-yellow-one;
			display: block;
			text-align: center;
			@include respond-to(medium){
				text-align: left;
			}
		}
		.spec{
			display: block;
			text-align: center;
			@include respond-to(medium){
				text-align: left;
			}
		}
		.link{
			display: block;
			margin-bottom: 20px;
			text-align: center;
			a{
				color: $clr-grey-five;
				&:hover, &:focus{
					text-decoration: underline;
				}
			}
			@include respond-to(medium){
				text-align: left;
			}
		}
	}
	@include respond-to(small){
		padding-right: 15px;
		margin-bottom: 50px;
	}
}


.sidemenu{
	width: 100%;
	list-style: none;
	padding: 0;
	margin: 0;
	background: $clr-orange-light-one;
	padding: 20px 20px;
	display: none;
	> li{
		display: block;
		margin: 10px 0;
		&:first-child{
			margin-top: 0;
		}
		&:last-child{
			margin-bottom: 0;
		}
		a{
			@include font-size(1.6);
			display: inline-block;
			color: $clr-brown-one;
			font-weight: 600;
			position: relative;
			padding-left: 15px;
			&:before{
				content: "";
				width: 3px;
				height: 3px;
				top: 50%;
				left:0;
				position: absolute;
				display: block;
				background: rgba($clr-brown-one,.4);
				margin-top: -2px;
			}
		}
	}
	@include respond-to(small){
		display: block;
	}
}


/*.split-two{
	@include make-row();
	padding: 0;
	list-style: none;
	> li {
		@include make-sm-column(5);
		&:last-child{
			@include make-sm-column(7);
		}
	}
	
}*/



.embed-responsive-16by9{
	&.map{
		
		margin-bottom: 30px;
		@include respond-to(small){
			padding-bottom: 42%;
			margin-bottom: 50px;
		}
	}
}

.section-contact{
	.sm-box{
		&:first-child{
			padding-top: 0;
		}
	}
}

.sm-box{
	@include font-size(1.6);
	border-bottom: 1px $clr-orange-light-one solid;
    padding: 0 0 30px 0;
    margin-bottom: 30px;
	.title{
		@include font-size(2.4);
		font-family: $font-family-serif;
		font-weight: 700;
		position: relative;
		margin: 0;
		color: $clr-brown-one;
		display: block;
	}
	.split-two{
		@include make-row();
	
		p{
			white-space: normal;
			position: relative;
			@include make-sm-column(12);
			@include make-md-column(7);
			margin-bottom: 0;
			&:before{
			  content: "";
				height: 1px;
				display: block;
				position: absolute;
				left: 15px;
				right: 15px;
				bottom: -10px;
				background: $clr-orange-light-one;
				@include respond-to(small){
					bottom: -15px;
				}
				@include respond-to(medium){
					bottom: auto;
					width: 1px;
					height: 100%;
					right:0;
					left: auto;
				}
			}
			&:first-child{
				@include make-sm-column(12);
				@include make-md-column(5);
				@include respond-to(large){
					padding-left: 20px;
					padding-right: 20px;
					min-height: 75px;
				}
			}
			&:last-child{
				&:before{
					display: none;
				}
			}
			.twin{
				display: block;
				margin-bottom: 20px;
				&:last-child{
					margin-bottom: 0;
				}
				@include respond-to(small){
					display: table-cell;
					width: auto;
					vertical-align: top;
					padding: 0 20px 0 0;
				}
				@include respond-to(medium){
					padding: 0 20px;
				}
			}
			@include respond-to(large){
				padding-left: 20px;
				padding-right: 20px;
				min-height: 75px;
			}
		}
		@include respond-to(large){
			margin: 0 -20px;
		}
	}
	.btm-cnt{	
		margin-top: 20px;
		line-height: 1.6;	
		p{
			margin-bottom: 20px;
			&.sub-title{
				@include font-size(1.8);
				color: $clr-yellow-one;
				font-weight: 700;
				
			}
		}
		.split-two{
			p{
				margin-bottom: 20px;
				&:last-child{
					margin-bottom: 0;
				}
				@include respond-to(small){
					margin-bottom: 30px;
				}
				@include respond-to(medium){
					margin-bottom:0;
				}
			}
		}
		@include respond-to(small){
			margin-top: 30px
		}
	}
	@include respond-to(small){
		    padding: 0 0 50px 0;
    margin-bottom: 40px;
	}
}


.split-three{
	@include make-row();
	padding: 0;
	list-style: none;
	margin-bottom: 0;
	> li {
		@include make-sm-column(4);
		&.full{
			@include make-sm-column(12);
		}
	}
}

.form-contact{
	margin-top: 25px;
	.form-group{
		margin-bottom: 20px;
		@include respond-to(small){
			margin-bottom: 30px;
		}
	}
	.form-control{
		@include borderRadius(0px);
		@include box-shadow(0px 0px 0px rgba(0, 0, 0, 0.075) inset );
		@include font-size(1.6);
		color: $clr-black-two;
		@include x-appearance(none);
		height: 45px;
		padding: 6px 15px;
		border-color: $clr-orange-light-one;
		background-color: $clr-orange-light-one;
		&:hover, &:focus{
			background-color: transparent;
			border-color: $clr-yellow-one;
		}
	}
	.has-error{
		.form-control{
			@include borderRadius(0px);
			@include box-shadow(0px 0px 0px rgba(0, 0, 0, 0.075) inset );
			@include font-size(1.6);
			border-color: red;
		}
	}
	textarea.form-control{
		height: auto;
		min-height: 250px;
		padding: 15px;
	}
	
}



.subscribe-scl{
	display: block;
	width: 100%;
	font-size: 0px;
	list-style: none;
	padding: 0;
	margin: 30px 0;
	>li{
		display: inline-block;
		width: 50%;
		@include font-size(1.5);
		font-weight: 700;
		a{
			padding: 10px;
			display: block;
			text-align: center;
			color: white;
			@include borderRadius(6px);
			margin: 0 10px;
			.fa{
				padding-right: 8px;
			}
			&:hover, &:focus{
				@include opacity(0.9);
				text-decoration: none;
			}
			&.sub-fb{
				background-color: $clr-blue-fb;
			}
			&.sub-twt{
				background-color: $clr-blue-twt;
			}
		}
	}
}



