/*$breakpoints: ( 
	'xs-phone': 320px,
	'phone': 480px,
	'tablet': 768px,
	'tablet-lg':992px,
	'desktop': 1024px,
	'desktop-lg': 1200px,
	'widescreen': 1380px);*/
// keywords
$media-expressions: ( 'screen': 'screen', 'print': 'print', 'handheld': 'handheld', 'landscape': '(orientation: landscape)', 'portrait': '(orientation: portrait)', 'retina2x': '(-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi)', 'retina3x': '(-webkit-min-device-pixel-ratio: 2.5), (min-resolution: 240dpi)');
// ligatured operators ≥ ≤
@import 'custom';
@import 'bootstrap';
@import 'breakpoints';
@import 'owl-carousel';
@import "dropdown";
@import "font-awesome";


/*@import "bootstrap-custom";
@import "vendors/bootstrap/variables";
@import "vendors/bootstrap/mixins";
@import 'base/mixins';
@import 'base/helpers';
@import "layout/buttons";

@import "layout/common";
*/
@import "layouts/header";
@import "layouts/footer";
@import "layouts/body";

/*@import "layout/navbar";
@import "layout/owlcarousel";

@import "layout/checkbox";
@import "layout/pages/home";
@import "layout/pages/profile";
@import "layout/pages/process";
@import "layout/pages/bio";*/
